
















































import { Component } from 'vue-property-decorator'

import { ImageForm, LinkForm, NavigationForm } from '../../../../../../components/molecules'
import { MarkdownEditor } from '../../../../../../components/molecules/MarkdownEditor'
import { UnresolvedImage } from '../../../../../../contracts'

import { AbstractSectionForm } from './AbstractSectionForm'
import { FooterModuleContentBottom } from '../../../Footer.contracts'

/**
 * Form to collect data for `FooterModuleContentBottom`.
 *
 * @see FooterModuleContentBottom
 * Component used as a partial in FooterModuleForm
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component<BottomSectionForm>({
  name: 'BottomSectionForm',
  components: { ImageForm, LinkForm, MarkdownEditor, NavigationForm }
})
export class BottomSectionForm extends AbstractSectionForm<FooterModuleContentBottom> {
  /**
   * @inheritDoc
   */
  public initialSectionContent: FooterModuleContentBottom = {
    disclaimer: '',
    brandImage: null,
    brandLink: null,
    motto: '',
    copyright: '',
    nav: null
  }

  public get image (): UnresolvedImage | null {
    if (!this._section.brandImage) {
      return null
    }
    return {
      image: this._section.brandImage
    }
  }

  public set image (image: UnresolvedImage | null) {
    if (!image) {
      const sectionCopy = { ...this._section }
      sectionCopy.brandImage = null
      this._section = sectionCopy
      return
    }
    this._section = {
      ...this._section,
      brandImage: image.image
    }
  }

  public get disclaimer (): string {
    if (!this._section.disclaimer) {
      return ''
    }
    return this._section.disclaimer
  }

  public set disclaimer (model: string) {
    this._section = {
      ...this._section,
      disclaimer: model
    }
  }
}

export default BottomSectionForm
