













import { Component, Vue } from 'vue-property-decorator'
import { DashmixBoxTabItem } from '@movecloser/ui-core'
import { VueConstructor } from 'vue'

import { AbstractModuleForm } from '../../_abstract'

import {
  AnySection,
  FooterModule,
  FooterModuleContent,
  FooterModuleContentBottom,
  FooterModuleContentMiddle,
  FooterModuleContentTop,
  FooterSectionType
} from '../Footer.contracts'
import { footerContentFactory } from '../Footer.factory'
import { footerSectionsForm } from './Footer.form.helpers'

/**
 * TODO: Document all methods and properties.
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl> (edited)
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 */
@Component<FooterModuleForm>({
  name: 'FooterModuleForm',
  components: {}
})
export class FooterModuleForm extends AbstractModuleForm<FooterModule> {
  public initialContent: FooterModuleContent = footerContentFactory()

  public activeTab: FooterSectionType = FooterSectionType.Top

  public tabs: DashmixBoxTabItem[] = [
    {
      tab: {
        id: FooterSectionType.Top,
        label: `${this.$t('forms.Footer.top.title')}`
      }
    },
    {
      tab: {
        id: FooterSectionType.Middle,
        label: `${this.$t('forms.Footer.middle.title')}`
      }
    },
    {
      tab: {
        id: FooterSectionType.Bottom,
        label: `${this.$t('forms.Footer.bottom.title')}`
      }
    }
  ]

  public get activeTabComponent (): VueConstructor<Vue> {
    if (!footerSectionsForm[this.activeTab]) {
      throw new Error(`FooterModuleForm no registered component for [${this.activeTab}]`)
    }
    return footerSectionsForm[this.activeTab]
  }

  public get activeSection (): AnySection | undefined {
    if (!this._content[this.activeTab]) {
      throw new Error(`FooterModuleForm no section data for [${this.activeTab}]`)
    }
    return this._content[this.activeTab]
  }

  public set activeSection (section: AnySection | undefined) {
    if (!this._content[this.activeTab]) {
      throw new Error(`FooterModuleForm no section data for [${this.activeTab}]`)
    }

    // FIXME - here TS beat me and I can't handel it to accept `this._content[this.activeTab] = section`
    // this._content[this.activeTab] = section
    switch (this.activeTab) {
      case FooterSectionType.Bottom:
        this._content = {
          ...this._content,
          bottom: section as FooterModuleContentBottom
        }
        return
      case FooterSectionType.Top:
        this._content = {
          ...this._content,
          top: section as FooterModuleContentTop
        }
        return
      case FooterSectionType.Middle:
        this._content = {
          ...this._content,
          middle: section as FooterModuleContentMiddle
        }
    }
  }
}

export default FooterModuleForm
