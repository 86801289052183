// Copyright © 2022 Move Closer

import { VueConstructor } from 'vue'

import { NavbarSectionType } from '../Navbar.contracts'
import { TopSectionForm, MainSectionForm } from './partials'

// FIXME - here TS beat me and I can't handel it to accept Record<FooterSectionType, AbstractSectionForm<AnySection>>
/**
 * Registry of Navbar section form components
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const navbarSectionsForm: Record<NavbarSectionType, VueConstructor> = {
  [NavbarSectionType.Top]: TopSectionForm,
  [NavbarSectionType.Main]: MainSectionForm
}
