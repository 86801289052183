


























import { Component } from 'vue-property-decorator'

import { NavigationForm } from '../../../../../components/molecules'

import { AbstractSectionForm } from './AbstractSectionForm'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'TopSectionForm',
  components: { NavigationForm }
})
export class TopSectionForm extends AbstractSectionForm {
}

export default TopSectionForm
