













import { DashmixBoxTabItem } from '@movecloser/ui-core'
import { VueConstructor } from 'vue'
import { Component, Vue } from 'vue-property-decorator'

import { AbstractModuleForm } from '../../_abstract'

import { navbarContentFactory } from '../Navbar.factory'
import { NavbarModule, NavbarModuleContent, NavbarSectionType } from '../Navbar.contracts'
import { navbarSectionsForm } from './Navbar.form.helpers'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl> (edited)
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 */
@Component<NavbarModuleForm>({
  name: 'NavbarModuleForm'
})
export class NavbarModuleForm extends AbstractModuleForm<NavbarModule> {
  /**
   * @inheritDoc
   */
  public initialContent: NavbarModuleContent = navbarContentFactory()

  public activeTab: NavbarSectionType = NavbarSectionType.Top

  public tabs: DashmixBoxTabItem[] = [
    {
      tab: {
        id: NavbarSectionType.Top,
        label: `${this.$t('forms.Navbar.top.title')}`
      }
    },
    {
      tab: {
        id: NavbarSectionType.Main,
        label: `${this.$t('forms.Navbar.main.title')}`
      }
    }
  ]

  public get activeTabComponent (): VueConstructor<Vue> {
    if (!navbarSectionsForm[this.activeTab]) {
      throw new Error(`NavbarModuleForm no registered component for [${this.activeTab}]`)
    }
    return navbarSectionsForm[this.activeTab]
  }
}

export default NavbarModuleForm
