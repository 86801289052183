




















































import { Component } from 'vue-property-decorator'

import { ImageForm, LinkForm, NavigationForm } from '../../../../../components/molecules'
import { UnresolvedImage } from '../../../../../contracts'

import { AbstractSectionForm } from './AbstractSectionForm'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'MainSectionForm',
  components: { ImageForm, LinkForm, NavigationForm }
})
export class MainSectionForm extends AbstractSectionForm {
  public get image (): UnresolvedImage | null {
    if (!this._content.brandImage) {
      return null
    }
    return {
      image: this._content.brandImage
    }
  }

  public set image (image: UnresolvedImage | null) {
    if (!image) {
      const contentCopy = { ...this._content }
      delete contentCopy.brandImage
      this._content = contentCopy
      return
    }
    this._content = {
      ...this._content,
      brandImage: image.image
    }
  }
}

export default MainSectionForm
