// Copyright © 2022 Move Closer

import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { merge } from 'lodash'

import { PickerCallback } from '../../../../../../contracts'
import { ResolvesRelated, ResolvesRelatedAsync } from '../../../../../../services'

import { AnySection } from '../../../Footer.contracts'

/**
 * Abstract component used in Footer sections form
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component<AbstractSectionForm<AnySection>>({
  mounted () {
    if (typeof this._section === 'undefined' || this._section === null) {
      this.setInitialSectionContent()
    }
  }
})
export class AbstractSectionForm<SectionData extends AnySection> extends Vue {
  /**
   * Content of navigation section
   */
  @PropSync('section', { type: Object, required: false, default: false })
  public _section!: SectionData

  /**
   * Handles picker selection
   */
  @Prop({ type: Function, required: false })
  public readonly pickRelated!: PickerCallback

  /**
   * Related Service instance
   */
  @Prop({ type: Object, required: true })
  protected readonly relatedService!: ResolvesRelated | ResolvesRelatedAsync

  /**
   * Section's initial content
   */
  public initialSectionContent: SectionData | null = null

  /**
   * Check if content section is define
   */
  public get hasSectionContent (): boolean {
    return typeof this._section !== 'undefined' && this._section !== null
  }

  /**
   * Merges `this.initialSectionContent` with the current value of `this._section`.
   */
  protected setInitialSectionContent (): void {
    if (this.initialSectionContent === null) {
      throw new Error(
        'AbstractSectionForm.initialSectionContent(): [this.initialSectionContent] is [null], but its value should be set from/inside the subclass!')
    }

    this._section = { ...merge(this.initialSectionContent, this._section) }
  }
}
