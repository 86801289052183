































import { Component } from 'vue-property-decorator'

import { NavigationForm } from '../../../../../../components/molecules'

import { AbstractSectionForm } from './AbstractSectionForm'
import { FooterContactSection } from '../FooterContactSection.vue'
import { FooterModuleContentMiddle } from '../../../Footer.contracts'

/**
 * Footer Middle Sections Form which collect data form `MiddleSectionForm` contract
 * Component used as a partial in FooterModuleForm
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component<MiddleSectionForm>({
  name: 'MiddleSectionForm',
  components: { NavigationForm, FooterContactSection }
})
export class MiddleSectionForm extends AbstractSectionForm<FooterModuleContentMiddle> {
  /**
   * @inheritDoc
   */
  public initialSectionContent: FooterModuleContentMiddle = {
    bottomNav: null,
    contactSection: {
      heading: '',
      iconNav: null,
      phones: {
        mainPhone: '',
        otherPhones: {
          commercialPhone: '',
          nonCommercialDesc: '',
          nonCommercialPhone: '',
          commercialDesc: ''
        }
      }
    },
    mainNav: null,
    socialNav: null
  }

  public get hasContactSection (): boolean {
    return typeof this._section !== 'undefined' && this._section !== null &&
      typeof this._section.contactSection !== 'undefined'
  }
}

export default MiddleSectionForm
