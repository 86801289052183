



















































































import { Component, PropSync, Vue } from 'vue-property-decorator'

import { FormFieldset } from '../../../../../components/atoms'
import { MarkdownEditor } from '../../../../../components/molecules/MarkdownEditor'

import { FooterPhones } from '../../Footer.contracts'

@Component({
  name: 'PhonesForm',
  components: { FormFieldset, MarkdownEditor }
})
export class PhonesForm extends Vue {
  @PropSync('phones', { type: Object, required: true })
  public _phones!: FooterPhones

  public get disclaimer (): string {
    if (!this._phones.disclaimer || typeof this._phones.disclaimer !== 'string') {
      return ''
    }
    return this._phones.disclaimer
  }

  public set disclaimer (model: string) {
    this._phones = {
      ...this._phones,
      disclaimer: model
    }
  }
}

export default PhonesForm
