












import { Component } from 'vue-property-decorator'

import { NavigationForm } from '../../../../../../components/molecules'

import { AbstractSectionForm } from './AbstractSectionForm'
import { FooterModuleContentTop } from '../../../Footer.contracts'

/**
 * Footer Top Sections Form which collect data form `FooterModuleContentTop` contract
 * Component used as a partial in FooterModuleForm
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component<TopSectionForm>({
  name: 'TopSectionForm',
  components: { NavigationForm }
})
export class TopSectionForm extends AbstractSectionForm<FooterModuleContentTop> {
  /**
   * @inheritDoc
   */
  public initialSectionContent: FooterModuleContentTop = {
    nav: null
  }
}

export default TopSectionForm
