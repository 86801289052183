// Copyright © 2022 Move Closer

import { Vue, Component, PropSync, Prop } from 'vue-property-decorator'

import { PickerCallback } from '../../../../../contracts'
import { ResolvesRelated, ResolvesRelatedAsync } from '../../../../../services'

import { NavbarModuleContent } from '../../Navbar.contracts'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'AbstractSectionForm'
})
export class AbstractSectionForm extends Vue {
  /**
   * Content of navbar
   */
  @PropSync('content', { type: Object, required: true })
  public _content!: NavbarModuleContent

  /**
   * Handles picker selection
   */
  @Prop({ type: Function, required: false })
  public readonly pickRelated!: PickerCallback

  /**
   * Related Service instance
   */
  @Prop({ type: Object, required: true })
  protected readonly relatedService!: ResolvesRelated | ResolvesRelatedAsync
}
