



























import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

import { PickerCallback } from '../../../../../contracts'
import { ResolvesRelated, ResolvesRelatedAsync } from '../../../../../services'

import { FormFieldset } from '../../../../atoms'
import { NavigationForm } from '../../../../molecules'

import { FooterModuleContentMiddle } from '../../Footer.contracts'
import { PhonesForm } from './PhonesForm.vue'

@Component<FooterContactSection>({
  name: 'FooterContactSection',
  components: { FormFieldset, NavigationForm, PhonesForm },
  mounted () {
    if (!this.hasPhones) {
      this.addPhones()
    }
  }
})
export class FooterContactSection extends Vue {
  @PropSync('contactSection', { type: Object, required: true })
  public _contactsSection!: FooterModuleContentMiddle['contactSection']

  /**
   * Handles picker selection
   */
  @Prop({ type: Function, required: false })
  public readonly pickRelated!: PickerCallback

  /**
   * Related Service instance
   */
  @Prop({ type: Object, required: true })
  protected readonly relatedService!: ResolvesRelated | ResolvesRelatedAsync

  public get hasPhones (): boolean {
    return Object.prototype.hasOwnProperty.call(this._contactsSection, 'phones')
  }

  public addPhones (): void {
    if (!this._contactsSection) {
      return
    }

    this._contactsSection.phones = {
      mainPhone: '',
      otherPhones: {
        commercialPhone: '',
        nonCommercialDesc: '',
        nonCommercialPhone: '',
        commercialDesc: ''
      }
    }
  }
}

export default FooterContactSection
