// Copyright © 2022 Move Closer

import { VueConstructor } from 'vue'

import { FooterSectionType } from '../Footer.contracts'
import { BottomSectionForm, MiddleSectionForm, TopSectionForm } from './partials'

// FIXME - here TS beat me and I can't handel it to accept Record<FooterSectionType, AbstractSectionForm<AnySection>>
/**
 * Registry of Footer section form components
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const footerSectionsForm: Record<FooterSectionType, VueConstructor> = {
  [FooterSectionType.Bottom]: BottomSectionForm,
  [FooterSectionType.Middle]: MiddleSectionForm,
  [FooterSectionType.Top]: TopSectionForm
}
