












import { Component } from 'vue-property-decorator'

import { FormFieldset } from '../../../atoms'
import { LinkForm } from '../../../molecules'

import { AbstractModuleForm } from '../../_abstract'

import { floatingButtonContentFactory } from '../FloatingButton.factory'
import { FloatingButtonModule, FloatingButtonModuleContent } from '../FloatingButton.contracts'

/**
 * TODO: Document all methods and properties.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<FloatingButtonModuleForm>({
  name: 'FloatingButtonModuleForm',
  components: { FormFieldset, LinkForm }
})
export class FloatingButtonModuleForm extends AbstractModuleForm<FloatingButtonModule> {
  /**
   * @inheritDoc
   */
  public initialContent: FloatingButtonModuleContent = floatingButtonContentFactory()
}

export default FloatingButtonModuleForm
